import * as React from 'react';
import Layout from '../layouts';

export default () => (
  <Layout>
    <div className="row">
      <div className="contain-row">
        <div className="col-xs-12">
          <h1>404 -- Page not found.</h1>
        </div>
      </div>
    </div>
  </Layout>
);
